import React, { useEffect, useState } from "react";
import { Button, Spin, message } from "antd";
import Tabs from "../../components/Tabs";
import ExportButton from "../../components/Styles/ExportButton";
import CustomFilterHeader from "../../components/CustomFilterHeader";
import { Helmet } from "react-helmet";
import CustomAppBar from "../../components/CustomAppBar";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import { PostValues } from "../../utils/api-client";
import PriceCampaignDetails from "./priceCampaignDetails";
import AddCampaignModal from "./productUploadModel";
import ProductDetails from "./productDetails";

const TabsList = ["Price Track","product"];
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`nav-tabpanel-${index}`}
      aria-labelledby={`nav-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box pt={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}
const PriceTrackingIndex = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [columns, setColumns] = useState([]);
  const [marketPlaces, setMarketPlaces] = useState([]);
  const [requestData, setRequestData] = useState({});
  const [totalRecords, setTotalRecords] = useState(0);
  const [tabValue, setTabValue] = useState(0);
  const [searchText, setSearchText] = useState("");
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isExportLoading, setIsExportLoading] = useState(false);
  const [isUrlModalVisible, setIsUrlModalVisible] = useState(false);
  const [isProductModalVisible, setIsProductModalVisible] =
    React.useState(false);

  const getExportApi = async (requestData) => {
    let response = await PostValues("getOrganisationReportApi", requestData);
    return response;
  };
  const ExportAllData = async () => {
    try {
      setIsExportLoading(true);
      let exportJsonBody = {
        params: {
          channels: marketPlaces.map((mp) => mp.name),
        },
        reportType: "KeywordTracker",
      };
      const data = await getExportApi(exportJsonBody);
      if (data.status) {
        setIsExportLoading(false);
        message.success(
          "The export is being processed, please check the Downloads page ",
          10
        );
      } else {
        setIsExportLoading(false);
      }
    } catch (error) {
      setIsExportLoading(false);
    }
  };
  const isCreateNewLinkModalOpen = () => {
    setIsUrlModalVisible(true);
  };
  const onSearchTextEntered = (searchText) => {
    //  setSearchText(searchText);
  };
  const handleMarketplaceChange = async (selectedMarketplaces) => {
    setIsLoading(false);
    setMarketPlaces(selectedMarketplaces);
    // getAllData(selectedMarketplaces)
  };
  const handleManualSyncClick = (event) => {};
  const onSearch = async (ds, searchText) => {
    let params = { ...requestData };
    if (params.filters) {
      params.filters.search = searchText;
    } else {
      params.filters = {
        search: searchText,
      };
    }
    if (tabValue == 0) {
    }
    return [];
  };

  return (
    <>
      <Helmet>
        <title>{"Forcesight"}</title>
      </Helmet>
      <CustomAppBar />
      <CustomFilterHeader
        headerTitle="Price Tracking"
        showDateRanges={false}
        searchHide={true}
        searchFunction={onSearch}
        handleManualSyncClick={handleManualSyncClick}
        handleMarketplaceChange={handleMarketplaceChange}
        onSearchTextEntered={onSearchTextEntered}
        showExportButton={false}
        getExportData={ExportAllData}
        columns={columns}
        bulkUpload={true}
        isLoading={isLoading}
        showManualSync={false}
        paymentContent={true}
        customToggleProfit={
          <Button
            type="primary"
            onClick={() => {
              setIsProductModalVisible(true);
            }}
          >
            Add Campaign
          </Button>
        }
        onFilterUpdate={(data) => {}}
      />
      <div style={{ marginLeft: 30, marginRight: 30 }}>
        <Tabs
          value={tabValue}
          list={TabsList}
          onChange={(value) => {
            setTabValue(value);
          }}
        />
      </div>
      <div style={{ marginLeft: 20, marginRight: 20 }}>
        <TabPanel value={tabValue} index={0}>
          <PriceCampaignDetails />
        </TabPanel>
        <TabPanel value={tabValue} index={1}>
            <ProductDetails />
        </TabPanel>
        <AddCampaignModal
          setIsLoading={setIsLoading}
          isLoading={isLoading}
          isModalVisible={isProductModalVisible}
          setIsModalVisible={setIsProductModalVisible}
        />
      </div>
    </>
  );
};
export default PriceTrackingIndex;
