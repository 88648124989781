export const ApiTable = {
  loginSelf: {
    callType: "POST",
    isAuthenticated: false,
    baseUrl: process.env.REACT_APP_API_LOGIN_URL,
    endpoint: "/login/self",
  },
  refreshTokenSelf: {
    callType: "POST",
    isAuthenticated: true,
    baseUrl: process.env.REACT_APP_API_LOGIN_URL,
    endpoint: "/refresh-token/self",
  },
  sendOtpSelf: {
    callType: "POST",
    isAuthenticated: true,
    baseUrl: process.env.REACT_APP_API_PROFILE_URL,
    endpoint: "/send-otp/self",
  },
  forgotPasswordSelf: {
    callType: "POST",
    isAuthenticated: false,
    baseUrl: process.env.REACT_APP_API_PROFILE_URL,
    endpoint: "/forgot-password/self",
  },
  verifyOtpSelf: {
    callType: "POST",
    isAuthenticated: true,
    baseUrl: process.env.REACT_APP_API_PROFILE_URL,
    endpoint: "/verify-otp/self",
  },
  registerSelf: {
    callType: "POST",
    isAuthenticated: false,
    baseUrl: process.env.REACT_APP_API_PROFILE_URL,
    endpoint: "/signup/self",
  },
  createUser: {
    callType: "POST",
    isAuthenticated: true,
    baseUrl: process.env.REACT_APP_API_PROFILE_URL,
    endpoint: "/signup/admin",
  },
  RemoveMember: {
    callType: "POST",
    isAuthenticated: true,
    baseUrl: process.env.REACT_APP_API_PROFILE_URL,
    endpoint: "/remove-member/admin",
  },
  verifyOtpAdmin: {
    callType: "POST",
    isAuthenticated: true,
    baseUrl: process.env.REACT_APP_API_PROFILE_URL,
    endpoint: "/verify-otp/admin",
  },
  getOrders: {
    callType: "POST",
    isAuthenticated: true,
    baseUrl: process.env.REACT_APP_API_ORDER_URL,
    endpoint: "/get-orders",
  },

  getOrderReturns: {
    callType: "POST",
    isAuthenticated: true,
    baseUrl: process.env.REACT_APP_API_PAYMENT_URL,
    endpoint: "/get-orders-returns",
  },
  getPaymentReconciliation: {
    callType: "POST",
    isAuthenticated: true,
    baseUrl: process.env.REACT_APP_API_PAYMENT_URL,
    endpoint: "/get-payments-reconciliation",
  },
  getEstimatedReconciliation: {
    callType: "POST",
    isAuthenticated: true,
    baseUrl: process.env.REACT_APP_API_PAYMENT_URL,
    endpoint: "/get-payments-estimations",
  },

  updateInventory: {
    callType: "POST",
    isAuthenticated: true,
    baseUrl: process.env.REACT_APP_API_LISTING_URL,
    endpoint: "/update-listings",
  },
  updateInventory_v2: {
    callType: "POST",
    isAuthenticated: true,
    baseUrl: process.env.REACT_APP_API_LISTING_URL,
    endpoint: "/update-listings-v2",
  },
  publishInventory: {
    callType: "POST",
    isAuthenticated: true,
    baseUrl: process.env.REACT_APP_API_LISTING_URL,
    endpoint: "/sync-inventory-outwards",
  },
  getStorageLocations: {
    callType: "POST",
    isAuthenticated: true,
    baseUrl: process.env.REACT_APP_API_LISTING_URL,
    endpoint: "/storage-location",
  },

  getViewFullfillmentCenters: {
    callType: "POST",
    isAuthenticated: true,
    baseUrl: process.env.REACT_APP_API_LISTING_URL,
    endpoint: "/view-fulfillment-centers",
  },
  // getPendingRequest: {
  //   callType: "POST",
  //   isAuthenticated: true,
  //   baseUrl: process.env.REACT_APP_API_LISTING_URL,
  //   endpoint: "/get-update-listings-pending-request",
  // },
  allReviews: {
    callType: "POST",
    isAuthenticated: true,
    baseUrl: process.env.REACT_APP_API_REVIEW_URL,
    endpoint: "/reviews",
  },

  productReviews: {
    callType: "POST",
    isAuthenticated: true,
    baseUrl: process.env.REACT_APP_API_REVIEW_URL,
    endpoint: "/product-reviews",
  },

  sellerReviews: {
    callType: "POST",
    isAuthenticated: true,
    baseUrl: process.env.REACT_APP_API_REVIEW_URL,
    endpoint: "/seller-reviews",
  },

  markReviewRead: {
    callType: "POST",
    isAuthenticated: true,
    baseUrl: process.env.REACT_APP_API_REVIEW_URL,
    endpoint: "/mark-review-v2",
  },

  getMasterSKUs: {
    callType: "POST",
    isAuthenticated: true,
    baseUrl: process.env.REACT_APP_API_LISTING_URL,
    endpoint: "/get-masterskus",
  },
  updateMasterSKU: {
    callType: "POST",
    isAuthenticated: true,
    baseUrl: process.env.REACT_APP_API_LISTING_URL,
    endpoint: "/update-listings-mapping",
  },

  // requestFlipkart: {
  //   callType: "POST",
  //   isAuthenticated: true,
  //   baseUrl: process.env.REACT_APP_API_CHANNEL_URL,
  //   endpoint: "/request-flipkart-channel-access",
  // },
  // requestAmazon: {
  //   callType: "POST",
  //   isAuthenticated: true,
  //   baseUrl: process.env.REACT_APP_API_CHANNEL_URL,
  //   endpoint: "/request-amazon-channel-access",
  // },
  // requestAmazonIndiaAds: {
  //   callType: "POST",
  //   isAuthenticated: true,
  //   baseUrl: process.env.REACT_APP_API_CHANNEL_URL,
  //   endpoint: "/request-amazon-ads-channel-access",
  // },
  requestChannel: {
    callType: "POST",
    isAuthenticated: true,
    baseUrl: process.env.REACT_APP_API_CHANNEL_URL,
    endpoint: "/add-channel",
  },

  changePassword: {
    callType: "POST",
    isAuthenticated: true,
    baseUrl: process.env.REACT_APP_API_PROFILE_URL,
    endpoint: "/change-password/self",
  },
  editUserInfo: {
    callType: "POST",
    isAuthenticated: true,
    baseUrl: process.env.REACT_APP_API_PROFILE_URL,
    endpoint: "/edit-user-info/self",
  },
  editUserAdmin: {
    callType: "POST",
    isAuthenticated: true,
    baseUrl: process.env.REACT_APP_API_PROFILE_URL,
    endpoint: "/edit-user-info/admin",
  },
  editOrganisation: {
    callType: "POST",
    isAuthenticated: true,
    baseUrl: process.env.REACT_APP_API_PROFILE_URL,
    endpoint: "/edit-organisation-info/admin",
  },
  changeRole: {
    callType: "POST",
    isAuthenticated: true,
    baseUrl: process.env.REACT_APP_API_PROFILE_URL,
    endpoint: "/change-role/admin",
  },
  getAmazonCategories: {
    callType: "POST",
    isAuthenticated: true,
    baseUrl: process.env.REACT_APP_API_LISTING_URL,
    endpoint: "/get-amazon-categories",
  },
  updateAmazonCategory: {
    callType: "POST",
    isAuthenticated: true,
    baseUrl: process.env.REACT_APP_API_LISTING_URL,
    endpoint: "/update-amazon-category",
  },
  updateAmazonCategoryReconciliation: {
    callType: "POST",
    isAuthenticated: true,
    baseUrl: process.env.REACT_APP_API_LISTING_URL,
    endpoint: "/update-amazon-category-reconciliation",
  },
  cockpit: {
    callType: "POST",
    isAuthenticated: true,
    baseUrl: process.env.REACT_APP_API_DASHBOARD_URL,
    endpoint: "/dashboard",
  },

  map: {
    callType: "POST",
    isAuthenticated: true,
    baseUrl: process.env.REACT_APP_API_MAP_URL,
    endpoint: "/get-state-city-count",
  },
  ScanGoodsQR: {
    callType: "POST",
    isAuthenticated: true,
    baseUrl: process.env.REACT_APP_API_DASHBOARD_URL,
    endpoint: "/temp-scratch-card",
  },
  MarkProductReturn: {
    callType: "POST",
    isAuthenticated: true,
    baseUrl: process.env.REACT_APP_API_DASHBOARD_URL,
    endpoint: "/mark-returns",
  },

  ProfitabiltyTrackersGst: {
    callType: "POST",
    isAuthenticated: true,
    baseUrl: process.env.REACT_APP_API_DASHBOARD_URL,
    endpoint: "/profitability_with_gst",
  },
  updateCostPerUnit: {
    callType: "POST",
    isAuthenticated: true,
    baseUrl: process.env.REACT_APP_API_LISTING_URL, //process.env.REACT_APP_API_LISTING_URL,
    endpoint: "/update-listings-v2",
  },

  createCampaign: {
    callType: "POST",
    isAuthenticated: true,
    baseUrl: process.env.REACT_APP_API_REVIEW_URL,
    endpoint: "/create-email-campaign",
  },
  getInventoryForCampaign: {
    callType: "POST",
    isAuthenticated: true,
    baseUrl: process.env.REACT_APP_API_LISTING_URL,
    endpoint: "/get-listings-email-campaign",
  },
  getCampaignDetails: {
    callType: "POST",
    isAuthenticated: true,
    baseUrl: process.env.REACT_APP_API_REVIEW_URL,
    endpoint: "/view-email-campaign",
  },
  deleteCampaign: {
    callType: "POST",
    isAuthenticated: true,
    baseUrl: process.env.REACT_APP_API_REVIEW_URL,
    endpoint: "/delete-email-campaign",
  },
  profitabilityDashboard: {
    callType: "POST",
    isAuthenticated: true,
    baseUrl: process.env.REACT_APP_API_DASHBOARD_URL,
    endpoint: "/profitability-v2",
  },
  manualSync: {
    callType: "POST",
    isAuthenticated: true,
    baseUrl: process.env.REACT_APP_API_CHANNEL_URL,
    endpoint: "/manual-sync",
  },
  manualPendingSync: {
    callType: "POST",
    isAuthenticated: true,
    baseUrl: process.env.REACT_APP_API_CHANNEL_URL,
    endpoint: "/get-manual-sync-pending-requests",
  },
  getCustomerDetails: {
    callType: "POST",
    isAuthenticated: true,
    baseUrl: process.env.REACT_APP_API_ADMIN_URL,
    endpoint: "/get-organisations",
  },
  getSyncDetails: {
    callType: "POST",
    isAuthenticated: true,
    baseUrl: process.env.REACT_APP_API_ADMIN_URL,
    endpoint: "/get-organisations-channels",
  },
  enterOrganisation: {
    callType: "POST",
    isAuthenticated: true,
    baseUrl: process.env.REACT_APP_API_ADMIN_URL,
    endpoint: "/enter-organisation",
  },
  ToggleOutwardSync: {
    callType: "POST",
    isAuthenticated: true,
    baseUrl: process.env.REACT_APP_API_ADMIN_URL,
    endpoint: "/toggle-outward-inventory-sync",
  },
  UpdateAdminDetails_v2: {
    callType: "POST",
    isAuthenticated: true,
    baseUrl: process.env.REACT_APP_API_ADMIN_URL,
    endpoint: "/admin-update-organisation-details-v2",
  },
  AdminManualSync: {
    callType: "POST",
    isAuthenticated: true,
    baseUrl: process.env.REACT_APP_API_ADMIN_URL,
    endpoint: "/admin-manual-sync",
  },
  cockpitReviews: {
    callType: "POST",
    isAuthenticated: true,
    baseUrl: process.env.REACT_APP_API_DASHBOARD_URL,
    endpoint: "/cockpit-reviews",
  },

  restockInventoryDetails: {
    callType: "POST",
    isAuthenticated: true,
    baseUrl: process.env.REACT_APP_API_LISTING_URL,
    endpoint: "/restock-inventory",
  },

  zohoSubscription: {
    callType: "POST",
    isAuthenticated: true,
    baseUrl: process.env.REACT_APP_API_PROFILE_URL,
    endpoint: "/zoho-subscription",
  },
  invoiceDownload: {
    callType: "POST",
    isAuthenticated: true,
    baseUrl: process.env.REACT_APP_API_PROFILE_URL,
    endpoint: "/zoho-invoice",
  },
  getResponse: {
    callType: "POST",
    isAuthenticated: true,
    baseUrl: process.env.REACT_APP_API_INTEGRATION,
    endpoint: "/get-response",
  },
  couponCode: {
    callType: "POST",
    isAuthenticated: true,
    baseUrl: process.env.REACT_APP_API_PROFILE_URL,
    endpoint: "/zoho-coupon",
  },
  freeTrial: {
    callType: "POST",
    isAuthenticated: true,
    baseUrl: process.env.REACT_APP_API_PROFILE_URL,
    endpoint: "/free-trial",
  },
  setFcPincode: {
    callType: "POST",
    isAuthenticated: true,
    baseUrl: process.env.REACT_APP_API_LISTING_URL,
    endpoint: "/set-fc-pincode",
  },
  updateAmazonChannelAccess: {
    callType: "POST",
    isAuthenticated: true,
    baseUrl: process.env.REACT_APP_API_CHANNEL_URL,
    endpoint: "/update-amazon-channel-access",
  },
  updateFlipkartChannelAccess: {
    callType: "POST",
    isAuthenticated: true,
    baseUrl: process.env.REACT_APP_API_CHANNEL_URL,
    endpoint: "/update-flipkart-channel-access",
  },
  updateChannelAccess: {
    callType: "POST",
    isAuthenticated: true,
    baseUrl: process.env.REACT_APP_API_CHANNEL_URL,
    endpoint: "/update-channel",
  },
  customerSyncStatus: {
    callType: "POST",
    isAuthenticated: true,
    baseUrl: process.env.REACT_APP_API_ADMIN_URL,
    endpoint: "/toggle-organisation-sync-status",
  },
  amazonPaymenttab: {
    callType: "POST",
    isAuthenticated: true,
    baseUrl: process.env.REACT_APP_API_MAP_URL,
    endpoint: "/payment_dashboard",
  },
  subscriptionEndDate: {
    callType: "POST",
    isAuthenticated: true,
    baseUrl: process.env.REACT_APP_API_ADMIN_URL,
    endpoint: "/admin-update-organisation-details",
  },
  getCouponData: {
    callType: "POST",
    isAuthenticated: true,
    baseUrl: process.env.REACT_APP_API_MAP_URL,
    endpoint: "/coupon_enable",
  },
  // carouselData: {
  //   callType: "POST",
  //   isAuthenticated: true,
  //   baseUrl: process.env.REACT_APP_API_DASHBOARD_URL,
  //   endpoint: "/business_feed",
  // },
  // useing this Api

  // Dashboard Api list start//
  cockpitReviews_v2: {
    callType: "POST",
    isAuthenticated: true,
    baseUrl: process.env.REACT_APP_API_DASHBOARD_URL,
    endpoint: "/cockpit-reviews-v2",
  },
  cockpit_v2: {
    callType: "POST",
    isAuthenticated: true,
    baseUrl: process.env.REACT_APP_API_DASHBOARD_URL,
    endpoint: "/dashboard-v2",
  },
  cockpit_Inventory_v2: {
    callType: "POST",
    isAuthenticated: true,
    baseUrl: process.env.REACT_APP_API_DASHBOARD_URL,
    endpoint: "/cockpit-inventory-v2",
  },
  profit_card: {
    callType: "POST",
    isAuthenticated: true,
    baseUrl: process.env.REACT_APP_API_DASHBOARD_URL,
    endpoint: "/profit_card",
  },
  profit_cardV2: {
    callType: "POST",
    isAuthenticated: true,
    baseUrl: process.env.REACT_APP_API_DASHBOARD_URL,
    endpoint: "/profit_card_v2",
  },
  profitabilityDashboard_v2: {
    callType: "POST",
    isAuthenticated: true,
    baseUrl: process.env.REACT_APP_API_DASHBOARD_URL,
    endpoint: "/cockpit-profitability-wt-v2",
  },
  profitabilityDashboard_v3: {
    callType: "POST",
    isAuthenticated: true,
    baseUrl: process.env.REACT_APP_API_DASHBOARD_URL,
    endpoint: "/cockpit-profitability-v2",
  },
  maps_stats_by_state: {
    callType: "POST",
    isAuthenticated: true,
    baseUrl: process.env.REACT_APP_API_DASHBOARD_URL,
    endpoint: "/map-stats-by-state",
  },
  // Dashboard Api list end //

  // Reviews Api list start //
  // Product Api list //
  productReviewsV2: {
    callType: "POST",
    isAuthenticated: true,
    baseUrl: process.env.REACT_APP_API_REVIEW_URL,
    endpoint: "/product-reviews-v2",
  },
  allReviewsV2: {
    callType: "POST",
    isAuthenticated: true,
    baseUrl: process.env.REACT_APP_API_REVIEW_URL,
    endpoint: "/reviews-v2",
  },
  // end //
  // seller Review Api list //
  sellerReviewsV2: {
    callType: "POST",
    isAuthenticated: true,
    baseUrl: process.env.REACT_APP_API_REVIEW_URL,
    endpoint: "/seller-reviews-v2",
  },
  // end //

  // Campain Api list //
  getCampaigns: {
    callType: "POST",
    isAuthenticated: true,
    baseUrl: process.env.REACT_APP_API_REVIEW_URL,
    endpoint: "/list-email-campaign",
  },
  getCampaignsReport: {
    callType: "POST",
    isAuthenticated: true,
    baseUrl: process.env.REACT_APP_API_REVIEW_URL,
    endpoint: "/email-campaign-report",
  },
  // end //
  // Reviews Api list end //

  // Payment Api list start//
  // Summary Tab //
  leaksPayment: {
    callType: "POST",
    isAuthenticated: true,
    baseUrl: process.env.REACT_APP_API_DASHBOARD_URL,
    endpoint: "/payment_dashboard_insights",
  },
  bankStatement: {
    callType: "POST",
    isAuthenticated: true,
    baseUrl: process.env.REACT_APP_API_DASHBOARD_URL,
    endpoint: "/bank_statement",
  },
  // end //
  // Settled Orders & Unsettled Orders Tab  AND (ORDER PAGE && RETURN PAGE) //
  getOrdersV3: {
    callType: "POST",
    isAuthenticated: true,
    baseUrl: process.env.REACT_APP_API_ORDER_URL,
    endpoint: "/get-orders-v3",
  },
  unSettledOrders: {
    callType: "POST",
    isAuthenticated: true,
    baseUrl: process.env.REACT_APP_API_DASHBOARD_URL,
    endpoint: "/unsettled_payment_orders",
  },
  // end //
  // Payment Api list end//

  // Profitablity Api V1 list start //
  // Product & order & channel Tab //
  ProfitabiltyTrackers: {
    callType: "POST",
    isAuthenticated: true,
    baseUrl: process.env.REACT_APP_API_DASHBOARD_URL,
    endpoint: "/profitability",
  },
  // end //
  // Input Product Cost Tab //
  getInventory: {
    callType: "POST",
    isAuthenticated: true,
    baseUrl: process.env.REACT_APP_API_LISTING_URL,
    endpoint: "/get-listings",
  },
  // end //
  // Profitablity Api list end//

  // Sales defence Api V1 list start //
  // buy box //
  BuyBoxMonitor: {
    callType: "POST",
    isAuthenticated: true,
    baseUrl: process.env.REACT_APP_API_INTEGRATION, //process.env.REACT_APP_API_LISTING_URL,
    endpoint: "/buybox",
  },
  BuyBoxMonitorV2: {
    callType: "POST",
    isAuthenticated: true,
    baseUrl: process.env.REACT_APP_API_INTEGRATION, //process.env.REACT_APP_API_LISTING_URL,
    endpoint: "/buybox-v2",
  },
  // end //
  // Brand Productor //
  getBrandList: {
    callType: "POST",
    isAuthenticated: true,
    baseUrl: process.env.REACT_APP_API_INTEGRATION,
    endpoint: "/brand-protector",
  },
  getBrandListV2: {
    callType: "POST",
    isAuthenticated: true,
    baseUrl: process.env.REACT_APP_API_INTEGRATION,
    endpoint: "/brand-protector-v2",
  },
  // end //
  // Sales defence Api V1 list end //

  // workflow manager Api V1 list start //
  // inventry Tracker //
  getInventory_V3: {
    callType: "POST",
    isAuthenticated: true,
    baseUrl: process.env.REACT_APP_API_LISTING_URL,
    endpoint: "/get-listings-v3",
  },
  // end //
  //  Master SKU Mapping AND PRODUCT CONFIG //
  getMappedListings: {
    callType: "POST",
    isAuthenticated: true,
    baseUrl: process.env.REACT_APP_API_LISTING_URL,
    endpoint: "/get-listings",
  },
  // end //
  // Storage Location //
  getStorageLocationsV2: {
    callType: "POST",
    isAuthenticated: true,
    baseUrl: process.env.REACT_APP_API_LISTING_URL,
    endpoint: "/storage-location-v2",
  },
  //end //
  // workflow manager Api V1 list end //

  // Setting Api V1 list start //
  getChannelURL: {
    callType: "POST",
    isAuthenticated: true,
    baseUrl: process.env.REACT_APP_API_CHANNEL_URL,
    endpoint: "/get-channels",
  },
  planDetails: {
    callType: "POST",
    isAuthenticated: true,
    baseUrl: process.env.REACT_APP_API_PROFILE_URL,
    endpoint: "/zoho-plan",
  },
  listUserAdmin: {
    callType: "POST",
    isAuthenticated: true,
    baseUrl: process.env.REACT_APP_API_PROFILE_URL,
    endpoint: "/list-users/admin",
  },
  getUserRoles: {
    callType: "POST",
    isAuthenticated: true,
    baseUrl: process.env.REACT_APP_API_PROFILE_URL,
    endpoint: "/get-user-roles",
  },
  updateUserRoles: {
    callType: "POST",
    isAuthenticated: true,
    baseUrl: process.env.REACT_APP_API_PROFILE_URL,
    endpoint: "/update-user-roles",
  },
  // Setting Api V1 list end //

  profitApiV2: {
    callType: "POST",
    isAuthenticated: true,
    baseUrl: process.env.REACT_APP_API_DASHBOARD_URL,
    endpoint: "/product_profitability",
  },
  profitApiV4: {
    callType: "POST",
    isAuthenticated: true,
    baseUrl: process.env.REACT_APP_API_DASHBOARD_URL,
    endpoint: "/profitability_insights",
  },
  profitSummaryCard: {
    callType: "POST",
    isAuthenticated: true,
    baseUrl: process.env.REACT_APP_API_DASHBOARD_URL,
    endpoint: "/profitability_cards",
  },
  profitSummaryTable: {
    callType: "POST",
    isAuthenticated: true,
    baseUrl: process.env.REACT_APP_API_DASHBOARD_URL,
    endpoint: "/profitability_table",
  },
  profitSummaryGraph: {
    callType: "POST",
    isAuthenticated: true,
    baseUrl: process.env.REACT_APP_API_DASHBOARD_URL,
    endpoint: "/profitability_graphs",
  },
  profitInputProductCost: {
    callType: "POST",
    isAuthenticated: true,
    baseUrl: process.env.REACT_APP_API_LISTING_URL,
    endpoint: "/get-listings-v3"
  },
  buyboxInsights: {
    callType: "POST",
    isAuthenticated: true,
    baseUrl: process.env.REACT_APP_API_INTEGRATION,
    endpoint: "/buybox-insight"
  },
  reviewsInsights: {
    callType: "POST",
    isAuthenticated: true,
    baseUrl: process.env.REACT_APP_API_REVIEW_URL,
    endpoint: "/reviews-insight"
  },
  reviewsInsightsCard: {
    callType: "POST",
    isAuthenticated: true,
    baseUrl: process.env.REACT_APP_API_REVIEW_URL,
    endpoint: "/reviews-insight-v2"
  },
  buyboxSummary: {
    callType: "POST",
    isAuthenticated: true,
    baseUrl: process.env.REACT_APP_API_INTEGRATION,
    endpoint: "/buybox-insight-summary"
  },
  buyboxInsightModal: {
    callType: "POST",
    isAuthenticated: true,
    baseUrl: process.env.REACT_APP_API_INTEGRATION,
    endpoint: "/buybox-insight-metric"
  },
  // all export 
  getOrganisationReportApi: {
    callType: "POST",
    isAuthenticated: true,
    baseUrl: process.env.REACT_APP_API_INTEGRATION,
    endpoint: "/create-organisation-report-request",
  },
  getExportData: {
    callType: "POST",
    isAuthenticated: true,
    baseUrl: process.env.REACT_APP_API_INTEGRATION,
    endpoint: "/check-organisation-report-request",
  },
  pincodeApi: {
    callType: "POST",
    isAuthenticated: true,
    baseUrl: process.env.REACT_APP_API_LISTING_URL,
    endpoint: "/view-fulfillment-centers",
  },
  updatePincodeApi: {
    callType: "POST",
    isAuthenticated: true,
    baseUrl: process.env.REACT_APP_API_LISTING_URL,
    endpoint: "/update-fulfillment-centers-data",
  },
  getExpiredFileData: {
    callType: "POST",
    isAuthenticated: true,
    baseUrl: process.env.REACT_APP_API_INTEGRATION,
    endpoint: "/download-organisation-report-request",
  },
  //------------------///


  payment_dashboard_cards: {
    callType: "POST",
    isAuthenticated: true,
    baseUrl: process.env.REACT_APP_API_DASHBOARD_URL,
    endpoint: "/payment_dashboard_cards",
  },
  reviewLog: {
    callType: "POST",
    isAuthenticated: true,
    baseUrl: process.env.REACT_APP_API_DASHBOARD_URL,
    endpoint: "/kpi-kpi",
  },

  updateChangeLog: {
    callType: "POST",
    isAuthenticated: true,
    baseUrl: process.env.REACT_APP_API_PROFILE_URL,
    endpoint: "/change_log",
  },
  updatePermission: {
    callType: "POST",
    isAuthenticated: true,
    baseUrl: process.env.REACT_APP_API_PROFILE_URL,
    endpoint: "/update-permissions",
  },
  queryUserActivity: {
    callType: "POST",
    isAuthenticated: true,
    baseUrl: process.env.REACT_APP_API_PROFILE_URL,
    endpoint: "/query-user-activity",
  },
  OrgDetails: {
    callType: "POST",
    isAuthenticated: true,
    baseUrl: process.env.REACT_APP_API_PROFILE_URL,
    endpoint: "/edit-organisation-info-v2",
  },
  DashboardMap: {
    callType: "POST",
    isAuthenticated: true,
    baseUrl: process.env.REACT_APP_API_MAP_URL,
    endpoint: "/profitability_maps",
  },
  DashboardMapTable: {
    callType: "POST",
    isAuthenticated: true,
    baseUrl: process.env.REACT_APP_API_DASHBOARD_URL,
    endpoint: "/sku_wise_profit_map",
  },
  StateBasedApi: {
    callType: "POST",
    isAuthenticated: true,
    baseUrl: process.env.REACT_APP_API_MAP_URL,
    endpoint: "/profitability_map_district",
  },
  sentimentAnalysis: {
    callType: "POST",
    isAuthenticated: true,
    baseUrl: process.env.REACT_APP_API_REVIEW_URL,
    endpoint: "/sentiment-analysis",
  },
  getSentimentKeyWords: {
    callType: "POST",
    isAuthenticated: true,
    baseUrl: process.env.REACT_APP_API_REVIEW_URL,
    endpoint: "/reviews-keyword",
  },
  getKeyWordsTrackers: {
    callType: "POST",
    isAuthenticated: true,
    baseUrl: process.env.REACT_APP_API_INTEGRATION,
    endpoint: "/get_keyword_tracker",
  },
  addKeyWordsTrackers: {
    callType: "POST",
    isAuthenticated: true,
    baseUrl: process.env.REACT_APP_API_INTEGRATION,
    endpoint: "/add_keywords",
  },
  deleteKeyWordsTrackers: {
    callType: "POST",
    isAuthenticated: true,
    baseUrl: process.env.REACT_APP_API_INTEGRATION,
    endpoint: "/delete_keywords",
  },
  CreateNewUrlApi: {
    callType: "POST",
    isAuthenticated: true,
    baseUrl: process.env.REACT_APP_API_MAP_URL,
    endpoint: "/url_rotators",
  },
  amazonIxdApi: {
    callType: "POST",
    isAuthenticated: true,
    baseUrl: process.env.REACT_APP_API_MAP_URL,
    endpoint: "/amazon_ixd",
  },
  keywordbulkUpload: {
    callType: "POST",
    isAuthenticated: true,
    baseUrl: process.env.REACT_APP_API_INTEGRATION,
    endpoint: "/add_keywords_bulk",
  },
  influencerCode: {
    callType: "POST",
    isAuthenticated: true,
    baseUrl: process.env.REACT_APP_API_LOGIN_URL,
    endpoint: "/influencer_code",
  },
  reverseLookup: {
    callType: "POST",
    isAuthenticated: true,
    baseUrl: process.env.REACT_APP_API_INTEGRATION,
    endpoint: "/get_reverse_lookup",
  },
  addReverseLookup: {
    callType: "POST",
    isAuthenticated: true,
    baseUrl: process.env.REACT_APP_API_INTEGRATION,
    endpoint: "/reverse_lookup",
  },
  reviewCallToAction: {
    callType: "POST",
    isAuthenticated: true,
    baseUrl: process.env.REACT_APP_API_REVIEW_URL,
    endpoint: "/reviews-insight-metric",
  },
  returnInsightsGraph: {
    callType: "POST",
    isAuthenticated: true,
    baseUrl: process.env.REACT_APP_API_MAP_URL,
    endpoint: "/returngraph",
  },
   returnInsightsCard: {
    callType: "POST",
    isAuthenticated: true,
    baseUrl: process.env.REACT_APP_API_MAP_URL,
    endpoint: "/return_test",
  },
  returnProductTable: {
    callType: "POST",
    isAuthenticated: true,
    baseUrl: process.env.REACT_APP_API_MAP_URL,
    endpoint: "/returntable",
  },
  return_Orders_v3: {
    callType: "POST",
    isAuthenticated: true,
    baseUrl: process.env.REACT_APP_API_MAP_URL,
    endpoint: "/returnorders",
  },
  return_Orders_toggle: {
    callType: "POST",
    isAuthenticated: true,
    baseUrl: process.env.REACT_APP_API_MAP_URL,
    endpoint: "/returntoggle",
  },
// Summary Inventory
  getSummaryInventory: {
    callType: "POST",
    isAuthenticated: true,
    baseUrl: process.env.REACT_APP_API_LISTING_URL,
    endpoint: "/inventory_insights",
  },
  getSummaryInventoryTable: {
    callType: "POST",
    isAuthenticated: true,
    baseUrl: process.env.REACT_APP_API_LISTING_URL,
    endpoint: "/inventory_table_insights",
  },
  // SellerFlex Upload
  sellerFlexUpload: {
    callType: "POST",
    isAuthenticated: true,
    baseUrl: process.env.REACT_APP_API_SELLERFLEX_URL,//REACT_APP_API_SELLERFLEX_URL
    endpoint: "/upload-file",
  },
  keywordsInsight: {
    callType: "POST",
    isAuthenticated: true,
    baseUrl: process.env.REACT_APP_API_INTEGRATION,
    endpoint: "/keyword_insights",
  },
};
