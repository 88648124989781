
const filterState = {
	marketplace: [
		{ name: "Amazon-India", id: "amazon-india", checked: true },
		{ name: "Flipkart", id: "flipkart", checked: true },
		{ name: "Meesho", id: "meesho", checked: true }
	],
	dateFilter: "today",
	startDate: null,
	endDate: null,
	reviewStatus: 0,
};

export default function filterReducer(state = filterState, action) {
	switch (action.type) {
		case "filter/updateMarketplace": {
			return {
				...state,
				marketplace: action.payload.marketplace,
			};
		}
		case "filter/updateDateFilter": {
			return {
				...state,
				dateFilter: action.payload.dateFilter,
				startDate: action.payload.startDate,
				endDate: action.payload.endDate,
			};
		}
		case "filter/updateReviewFilter": {
			return {
				...state,
				reviewStatus: action.payload.reviewStatus,
			};
		}
		default: {
			return state;
		}
	}
}
