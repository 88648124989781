import {
    CalendarOutlined,
    CopyOutlined,
    DeleteOutlined,
    EditOutlined,
    EyeOutlined,
  } from "@ant-design/icons";
  import React, { useRef } from "react";
  import AntTable from "../../components/AntTableV2/index.js";
  import { Button, message, Tooltip } from "antd";
  import { PostValues } from "../../utils/api-client.js";
import EditProductModal from "./editProduct.js";
  
  function PriceCampaignDetails(props) {
    const dataSource = [
        {
          key: '1',
          name: 'Mike',
          age: 32,
          address: '10 Downing Street',
        },
        {
          key: '2',
          name: 'John',
          age: 42,
          address: '10 Downing Street',
        },
      ];
    const [allUrls, setAllUrls] = React.useState([]);
    const [isUrlLoading, setIsUrlLoading] = React.useState(false);
    const [searchText, setSearchText] = React.useState("");
    const [columns, setColumns] = React.useState([]);
    const [isEditModalVisible, setIsEditModalVisible] = React.useState(false);
    const [editUrl, setEditUrl] = React.useState({});
    const [editArrayUrl, setEditArrayUrl] = React.useState([]);
    const [totalRecords, setTotalRecords] = React.useState(0);
    const [requestData, setRequestData] = React.useState({});
    const textAreaRef = useRef(null);
  
    const RefColumns = [
      {
        title: "Campaign",
        align: "center",
        isTotal: false,
        field: "name",
        tooltip: true,
        width: 80,
        render: (record, rowIndex, isLoading) =>
          isLoading ? (
            <div className="item">
              <div className="item-title" />
            </div>
          ) : (
            record.name || "-"
          ),
      },
      {
        title: (
          <div className="flex items-center justify-center">
            Created <CalendarOutlined className="pl-1" />
          </div>
        ),
        colName: "created Date",
        align: "center",
        isTotal: false,
        field: "age",
        tooltip: true,
        isFilterable: true,
        isSearchable: true,
        type: "date",
        width: 70,
      },
      {
        title: "Products Tracked",
        align: "center",
        isTotal: false,
        field: "age",
        tooltip: true,
        isFilterable: true,
        isSearchable: true,
        type: "date",
        width: 60,
      },
      {
        title: "Products Tracked",
        align: "center",
        isTotal: false,
        field: "age",
        tooltip: true,
        isFilterable: true,
        isSearchable: true,
        type: "date",
        width: 100,
      },
      {
        title: "Highest Price",
        align: "center",
        isTotal: false,
        field: "age",
        tooltip: true,
        isFilterable: true,
        isSearchable: true,
        type: "date",
        width: 100,
      },
      {
        title: "Lowest Price",
        align: "center",
        isTotal: false,
        field: "age",
        tooltip: true,
        isFilterable: true,
        isSearchable: true,
        type: "date",
        width: 60,
      },
      {
        title: "Action",
        align: "center",
        field: "action",
        width: 60,
        render: (record, rowIndex, isLoading) => {
          return (
            <>
             <Tooltip title="view product">
                <Button
                  icon={<EyeOutlined />}
                  onClick={() => {
                    // handleDeleteLink(record);
                  }}
                ></Button>
              </Tooltip>
              <Tooltip title="Edit product">
                <Button
                  icon={<EditOutlined />}
                  onClick={() => {
                    handleEditProduct(record);
                  }}
                ></Button>
              </Tooltip>
              <Tooltip title="Delete product">
                <Button
                  icon={<DeleteOutlined />}
                  onClick={() => {
                    // handleDeleteLink(record);
                  }}
                ></Button>
              </Tooltip>
            </>
          );
        },
      },
    ];
  
   
  
    React.useEffect(() => {
      setColumns(RefColumns);
    //   getUrlData();
    }, []);


  
  
  
    const handleEditProduct = async (record) => {
        console.log(record)
    //   let requestData = {
    //     filters: {
    //       short_url: record.short_url,
    //       method: "edit",
    //     },
    //   };
    //   let editUrl = await urlAPi(requestData);
    //   if (editUrl.response.Item) {
    //     setEditUrl(editUrl.response.Item);
    //     setEditArrayUrl([...editUrl.response?.Item.all_urls]);
    //   }
      setIsEditModalVisible(true);
    };
    // const handleDeleteLink = async (record) => {
    //   let requestData = {
    //     filters: {
    //       short_url: record.short_url,
    //       method: "delete",
    //     },
    //   };
    //   let deleteUrl = await urlAPi(requestData);
    //   if (deleteUrl.status) {
    //     let afterDeleteUrl = allUrls.filter(
    //       (item) => item.short_url !== record.short_url
    //     );
    //     setAllUrls(afterDeleteUrl);
    //     setTotalRecords(totalRecords - 1);
    //     message.success("Successfully Deleted");
    //   }
    // };
   
    const onSearchTextEntered = (searchText) => {
      setSearchText(searchText);
    };
  
    return (
      <>
        <div style={{ marginLeft: 20, marginRight: 20 }}>
          <AntTable
            columns={RefColumns}
            dataSource={dataSource}
            stickyHeader={{
              offsetHeader: 65,
              // offsetScroll: 216,
            }}
            // scroll={{ x: 1500 }}
            rowKey={(row, rowIndex) => rowIndex}
            isLoading={isUrlLoading}
            hideSummary={true}
            searchText={[searchText]}
            // totalRecords={totalRecords}
            // onDataChange={onTableChange}
          />
          {/* <NewLinkModel
            isModalVisible={props.isUrlModalVisible}
            setIsModalVisible={props.setIsUrlModalVisible}
            getUrlData={getUrlData}
          /> */}
          <EditProductModal
            isEditModalVisible={isEditModalVisible}
            setEditIsModalVisible={setIsEditModalVisible}
            // editUrl={editUrl}
            // setAllUrls={setAllUrls}
            // allUrls={allUrls}
            // editArrayUrl={editArrayUrl}
            // setEditArrayUrl={setEditArrayUrl}
          />
        </div>
      </>
    );
  }
  
  export default PriceCampaignDetails;
  