import moment from "moment";
import { Helmet } from "react-helmet";
import React, { Suspense, lazy } from "react";
import { BrowserRouter as Router, Routes, Route, Outlet, Navigate } from "react-router-dom";
import CustomAppBar from "./components/CustomAppBar";
import PriceTrackingIndex from "./pages/PriceTracking/priceTrackingIndex";

const LoginPage = lazy(() => import("./pages/auth/login"));
const DashboardV3 = lazy(() => import("./pages/cockpit/DashboardV3"));
const Signup = lazy(() => import("./pages/auth/signup"));
const NotAuth = lazy(() => import("./pages/NotAuth/NotAuth"));
const KeywordTracker = lazy(() => import("./pages/keywordTrack"));
const PaymentsBank = lazy(() => import("./pages/paymentsV2/indexV2"));
const ProfitabilityV4 = lazy(() => import("./pages/profitv2/indexV4"));
const ReviewsInsightsV4 = lazy(() => import("./pages/ReviewV2/indexV4"));
const MasterSKUMapping = lazy(() => import("./pages/inventory/MasterSKUMapping"));
const BuyBoxIndex = lazy(() => import("./pages/buyboxv2/index"));
const StorageLocations_V2 = lazy(() => import("./pages/inventory/storageLocations/indexV2"));
const ProductConfiguration = lazy(() => import("./pages/productConfiguration"));
const AllExport = lazy(() => import("./pages/ModuleExport"));
const Inventorytracker_V3 = lazy(() => import("./pages/inventory/InventoryTracker/inventorytracker_V3"));
const OrdersTrackerV3 = lazy(() => import("./pages/orders/OrdersTrackerV3"));
const ReturnsTrackerV3 = lazy(() => import("./pages/returns/ReturnsTrackerV3"));
const SyncDetails = lazy(() => import("./pages/adminSyncDetails/adminSyncDetails.js"));
const InventoryIndex = lazy(() => import("./pages/inventory/inventoryIndex.js"));
const CustomerDetails = lazy(() => import("./pages/AdminDashboard/adminDashboard.js"));
const DashboardMap = lazy(() => import("./pages/DashboardMap"));
const AccountSettings = lazy(() => import("./userSettings/UserSettings.js"));
const ChangeLog = lazy(() => import("./pages/changeLogs/index.js"));
const ReturnsInsights = lazy(() => import("./pages/ReturnsInsights"));
const ReverseLookup = lazy(() => import("./pages/reverseLookup"));
const QrCodeScanner = lazy(() => import("./pages/QRCodeScanner/qrCodeScanner.js"));
const ForgotPassword = lazy(() => import("./pages/auth/forgot"));
const Otp = lazy(() => import("./pages/auth/otp"));
const ChangePassword = lazy(() => import("./pages/auth/changePassword"));
const CreateCampaign = lazy(() => import("./pages/ReviewV2/Campaigns/CreateCampaign"));
const EditCampaign = lazy(() => import("./pages/ReviewV2/Campaigns/EditCampaign"));
const PageNotFound = lazy(() => import("./pages/PageNotFound/pageNotFound"));
const KeywordIndex = lazy(() => import("./pages/keywordTrack/summaryIndex"));


const AuthRoute = () => (
  localStorage.getItem("token") ? (
    userPermissions() ?
      !renewplanFunction() ? (
        <Outlet />
      ) : (
        <Navigate to="/account-settings" />
      )
      : <Navigate to='/not-auth' />
  ) : (
    <Navigate to="/login" />
  )
);

const userPermissions = () => {
  let dataArray = JSON.parse(localStorage.getItem('userPermission'))
  let pathName = (window.location.pathname.replace(/[^\w\s]/gi, '') + '_read').toUpperCase()
  if (dataArray.includes(pathName))
    return true
}

const renewplanFunction = () => {
  let days = localStorage.getItem("subscriptionDays");
  var start = new Date();
  var end = moment(days, "YYYY-MM-DD");
  const subscriptiondays = Math.round((end - start) / 1000 / 60 / 60 / 24) + 2;
  if (subscriptiondays <= 0) {
    return true;
  }
  return false;
};
const RoutesPage = (props) => {
  return (
    <Router {...props}>
      <Suspense fallback={<>
        <Helmet>
          <title>{"Forcesight"}</title>
        </Helmet>
        <CustomAppBar />
      </>}>
        <Routes>
          <Route element={<AuthRoute />}>
            <Route  path="/dashboard" element={<DashboardV3 />} />
          </Route>
          <Route element={<AuthRoute />}>
            <Route  path="/payment-tracker" element={<PaymentsBank />} />
          </Route>
          <Route element={<AuthRoute />}>
            <Route  path="/profitability" element={<ProfitabilityV4 />} />
          </Route>
          <Route element={<AuthRoute />}>
            <Route  path="/reviews" element={<ReviewsInsightsV4 />} />
          </Route>
          <Route element={<AuthRoute />}>
            <Route  path="/master-sku-mappin" element={<MasterSKUMapping />} />
          </Route>
          <Route element={<AuthRoute />}>
            <Route  path="/sales-defence" element={<BuyBoxIndex />} />
          </Route>
          <Route element={<AuthRoute />}>
            <Route  path="/storage-locations" element={<StorageLocations_V2 />} />
          </Route>
          <Route element={<AuthRoute />}>
            <Route  path="/product-config" element={<ProductConfiguration />} />
          </Route>
          <Route element={<AuthRoute />}>
            <Route  path="/admin-dashboard" element={<CustomerDetails />} />
          </Route>
          <Route element={<AuthRoute />}>
            <Route  path="/downloads" element={<AllExport />} />
          </Route>
          <Route element={<AuthRoute />}>
            <Route  path="/inventory-tracker" element={<Inventorytracker_V3 />} />
          </Route>
          <Route element={<AuthRoute />}>
            <Route  path="/orders-tracker" element={<OrdersTrackerV3 />} />
          </Route>
          <Route element={<AuthRoute />}>
            <Route  path="/returns-tracker" element={<ReturnsTrackerV3 />} />
          </Route>
          <Route element={<AuthRoute />}>
            <Route  path="/sync-details" element={<SyncDetails />} />
          </Route>
          <Route  path="/" element={<LoginPage />} />
          <Route  path="/login" element={<LoginPage />} />
          <Route  path="/register" element={<Signup />} />
          <Route  path="/keywords-tracker" element={<KeywordTracker />} />
          <Route  path="/inventory" element={<InventoryIndex />} />
          <Route  path="/map" element={<DashboardMap />} />
          <Route  path="/account-settings/*" element={<AccountSettings />} />
          <Route  path="/history" element={<ChangeLog />} />
          <Route  path="/return-insights" element={<ReturnsInsights />} />
          <Route  path="/lookup" element={<ReverseLookup />} />
          <Route  path="/barcode-scanner" element={<QrCodeScanner />} />
          <Route  path="/forgot-password" element={<ForgotPassword />} />
          <Route  path="/enter-otp" element={<Otp {...props} />} />
          <Route  path="/change-password" element={<ChangePassword {...props} />} />
          <Route  path="/create-campaigns" element={<CreateCampaign />} />
          <Route  path="/edit-campaigns/:id" element={<EditCampaign />} />
          <Route  path="/keywords-summary" element={<KeywordIndex />} />
          <Route  path="/price-tracking" element={<PriceTrackingIndex />} />
          <Route  path="/not-auth" element={<NotAuth />} />
          <Route path="*" element={<PageNotFound />} />
        </Routes>
      </Suspense>
    </Router>
  );
};
export default RoutesPage;
