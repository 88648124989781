import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom';
import { Line } from '@ant-design/plots';

export const ProductCharts = () => {

 
  const data = [
    {
        "name": "A",
        "year": "2000",
        "gdp": 1
      },
      {
        "name": "A",
        "year": "2001",
        "gdp": 1
      },
      {
        "name": "A",
        "year": "2002",
        "gdp": 1
      },
      {
        "name": "A",
        "year": "2003",
        "gdp": 1
      },
      {
        "name": "A",
        "year": "2004",
        "gdp": 1
      },
      {
        "name": "A",
        "year": "2005",
        "gdp": 1
      },
      
      {
        "name": "B",
        "year": "2000",
        "gdp": 2
      },
      {
        "name": "B",
        "year": "2001",
        "gdp": 2
      },
      {
        "name": "B",
        "year": "2002",
        "gdp": 2
      },
      {
        "name": "B",
        "year": "2003",
        "gdp": 2
      },
      {
        "name": "B",
        "year": "2004",
        "gdp": 2
      },
      {
        "name": "B",
        "year": "2005",
        "gdp": 2
      },
      {
        "name": "c",
        "year": "2000",
        "gdp": 4
      },
      {
        "name": "c",
        "year": "2001",
        "gdp": 4
      },
      {
        "name": "c",
        "year": "2002",
        "gdp": 4
      },
      {
        "name": "c",
        "year": "2003",
        "gdp": 4
      },
      {
        "name": "c",
        "year": "2004",
        "gdp": 4
      },
      {
        "name": "c",
        "year": "2005",
        "gdp": 4
      },
      
      {
        "name": "d",
        "year": "2000",
        "gdp": 3
      },
      {
        "name": "d",
        "year": "2001",
        "gdp": 3
      },
      {
        "name": "d",
        "year": "2002",
        "gdp": 3
      },
      {
        "name": "d",
        "year": "2003",
        "gdp": 3
      },
      {
        "name": "d",
        "year": "2004",
        "gdp": 3
      },
      {
        "name": "d",
        "year": "2005",
        "gdp": 3
      },
     
    ]
  const config = {
    data,
    xField: 'year',
    yField: 'gdp',
    seriesField: 'name',
    height: 200,
    yAxis: {
      label: {
        formatter: (v) => `${(v)}`,
      },
    },
    legend: {
      position: 'bottom',
    },
    smooth: true,
    animation: {
      appear: {
        animation: 'path-in',
        duration: 5000,
      },
    },
  };

  return <Line {...config} />;
};