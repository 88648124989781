import {
  Form,
  Input,
  Button,
  Space,
  Modal,
  Tooltip,
  message,
  Select,
  Upload,
} from "antd";
import { CopyOutlined, DeleteOutlined, PlusOutlined, UploadOutlined } from "@ant-design/icons";
import React from "react";
import { PostValues } from "../../utils/api-client";
const { Option } = Select;
const postUrlAPi = async (requestData) => {
  let response = await PostValues("CreateNewUrlApi", requestData);
  return response;
};

const channels = [
  { label: "Amazon-India", value: "Amazon-India" },
  { label: "Flipkart", value: "Flipkart" },
  { label: "Meesho", value: "Meesho" },
];

const AddCampaignModal = (props) => {
  const [newUrlLink, setNewUrlLink] = React.useState(null);
  const [selectedChannel, setSelectedChannel] = React.useState();
  const [existingProductId, setExistingProductId] = React.useState();
  const [form] = Form.useForm();

  const normFile = (e) => {
    console.log('Upload event:', e);
  
    if (Array.isArray(e)) {
      return e;
    }
  
    return e?.fileList;
  };
  const onFinish = async (values) => {
    console.log(values);
    //   let finalUrlArray = [];
    //   values.urls.map((val) => {
    //     let modifiedUrl = {
    //       channel: val.channel,
    //       url:
    //         val.channel === "amazon-India"
    //           ? `https://www.amazon.in/dp/${
    //               val.productId.trim()
    //             }/ref=sr_1_3?keywords=${val.keywords?.replace(/ /g, "+")}`
    //           : val.channel === "flipkart"
    //           ? `http://flipkart.com/dl/Forcesight-flipkart-url-redirect/p/itmdzthskkpuquue?pid=${
    //               val.productId.trim()
    //             }&q=${val.keywords?.replace(
    //               / /g,
    //               "%20"
    //             )}&marketplace=FLIPKART&srno=s_1_3&otracker=search&otracker1=search&fm=Search`
    //           : val.keywords,
    //       productId: val.productId,
    //       keywords: val.keywords,
    //     };
    //     finalUrlArray.push(modifiedUrl);
    //   });
    //   let requestData = {
    //     filters: {
    //       urls: finalUrlArray,
    //       method: "create",
    //       name: values.name,
    //     },
    //   };
    //   let newUrl = await postUrlAPi(requestData);
    //   if (newUrl.status) {
    //     setNewUrlLink(newUrl.response);
    //   }
    //   props.setIsModalVisible(false);
    //   form.resetFields();
    //   setExistingProductId();
    //   setSelectedChannel();
  };
  const handleOk = () => {
    form.submit();
  };
  const handleChange = (value) => {
    setSelectedChannel(value);
    // form.setFieldsValue({ urls: [] });
  };
  return (
    <Modal
      title="Generate New Campaign"
      visible={props.isModalVisible}
      onOk={handleOk}
      width={400}
      onCancel={() => {
        props.setIsModalVisible(false);
        form.resetFields();
        setExistingProductId();
        setSelectedChannel();
      }}
      closable={true}
      footer={[
        <div className={"flex items-center justify-end mt-2"}>
          <button
            className={`ml-2 pl-3 pr-3 pt-1 pb-1 rounded bg-GhostWhite border-platinum border mb-3`}
            onClick={() => {
              props.setIsModalVisible(false);
              form.resetFields();
              setExistingProductId();
              setSelectedChannel();
            }}
          >
            Cancel
          </button>
          <button
            className={`ml-3 pl-3 pr-3 pt-1 pb-1 rounded bg-primaryGreenStroke border-transparent border mb-3 text-white`}
            onClick={handleOk}
          >
            Submit
          </button>
        </div>,
      ]}
    >
      <Form
        form={form}
        name="dynamic_form_nest_item"
        onFinish={onFinish}
        autoComplete="off"
        initialValues={{
            product: [],
          url: "",
        }}
      >
        <Form.Item
          name="name"
          rules={[{ required: true, message: "Missing Campaign Name" }]}
        >
          <Input placeholder="Enter Campaign Name" />
        </Form.Item><br/>
        <Form.Item
        name="upload"
        label="Upload"
        valuePropName="fileList"
        getValueFromEvent={normFile}
      >
        <Upload name="logo" action="/upload.do" listType="picture">
          <Button icon={<UploadOutlined />}>Click to upload</Button>
        </Upload>
      </Form.Item>
        <Form.List name="product">
          {(fields, { add, remove }) => (
            <>
              {fields.map((field) => (
                <Space key={field.key} align="baseline">
                  <Form.Item
                    name={[field.name, "channel"]}
                    rules={[{ required: true, message: "Missing channel" }]}
                  >
                    <Select
                      options={channels}
                      placeholder="Select Channel Type"
                      style={{
                        width: 130,
                      }}
                      onChange={handleChange}
                    />
                  </Form.Item>
                  <Form.Item
                    {...field}
                    name={[field.name, "productId"]}
                    rules={[{ required: true, message: `Missing productId` }]}
                  >
                    <Input
                      placeholder={`Enter ProductId`}
                      onChange={(e) => {
                        setExistingProductId(e.target.value);
                      }}
                    />
                  </Form.Item>

                  {fields.length > 1 ? (
                    <div className=" flex flex-row-reverse	">
                      <DeleteOutlined onClick={() => remove(field.name)} />
                    </div>
                  ) : null}
                </Space>
              ))}

              <Form.Item>
                <Button
                  type="primary"
                  onClick={() =>
                    add({
                      productId: existingProductId,
                      channel: selectedChannel,
                    })
                  }
                  block
                  icon={<PlusOutlined />}
                  className="w-full justify-center mt-3"
                >
                  Add Product
                </Button>
              </Form.Item>
            </>
          )}
        </Form.List>
      </Form>
    </Modal>
  );
};

export default AddCampaignModal;
