import { ThemeProvider } from "@material-ui/core/styles";
import "antd/dist/antd.css";
import React, { useEffect } from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import IdleTimerContainer from "./components/SessionTimeOut/sessionTimeOut";
import { TableContext } from "./context/TableContent";
import { UserContext } from "./context/UserContext";
import { ChannelContext } from "./context/ChannelContext";
import "./axios/index.js";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import RoutesPage from "./Routes";
import store from "./store";
import { theme } from "./utils/Themes";
import { CookiesProvider } from "react-cookie";
import ApiWrapper from "./utils/ApiWrapper";

const App = () => {
  const isProduction = process.env.NODE_ENV === "production";
  const [tableRows, setTableRows] = React.useState([]);
  const [user, setUser] = React.useState({});
  const [company, setCompany] = React.useState({});
  const [currentChannel, setCurrentChannel] = React.useState([
    { name: "Amazon-India", id: "amazon-india", checked: true },
    { name: "Flipkart", id: "flipkart", checked: true },
    { name: "Meesho", id: "meesho", checked: true },
  ]);
  const channelValue = React.useMemo(
    () => ({ currentChannel, setCurrentChannel }),
    [currentChannel, setCurrentChannel]
  );
  let token = localStorage.getItem("token");

  useEffect(() => {
    window.process = {
      ...window.process,
    };
  }, []);
  useEffect(() => {
    if (token) {
      try {
        ApiWrapper("getChannelURL", {}).then((response) => {
          let arr = [];
          if (response.status) {
            const result = response.channels.filter(
              (channel) =>
                channel.isConnected && channel.channel !== "Amazon-India-Ads"
            );
            result.map((res) => {
              arr.push({ name: res.channel, id: res.channel, checked: true });
            });
            setCurrentChannel(arr);
            localStorage.setItem("channelLength", arr.length);
            console.log("in index page");
          } else {
            console.log("channelApi");
          }
        });
      } catch (error) {
        console.log(error);
      }
    }
  }, [token]);
  return (
    <CookiesProvider>
      <ThemeProvider theme={theme}>
        <ChannelContext.Provider value={channelValue}>
          <Provider store={store}>
            <TableContext.Provider
              value={{
                rows: tableRows,
                setRows: setTableRows,
              }}
            >
              <UserContext.Provider
                value={{
                  user,
                  setUser,
                  company,
                  setCompany,
                }}
              >
                <RoutesPage />
                <IdleTimerContainer />
              </UserContext.Provider>
            </TableContext.Provider>
          </Provider>
        </ChannelContext.Provider>
      </ThemeProvider>
    </CookiesProvider>
  );
};
ReactDOM.render(<App />, document.getElementById("root"));
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
