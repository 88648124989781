import { Button, Table } from "antd";
import React, { useState } from "react";
import { ProductCharts } from "./productPriceCharts";
const columns = [
  {
    title: "Product",
    dataIndex: "name",
  },
  {
    title: "Seller",
    dataIndex: "age",
  },
  {
    title: "Price",
    dataIndex: "age",
  },
  {
    title: "BSR",
    dataIndex: "age",
  },
  {
    title: "LT Ratings",
    dataIndex: "age",
  },
  {
    title: "LT Reviews",
    dataIndex: "age",
  },
];
const data = [];

for (let i = 0; i < 46; i++) {
  data.push({
    key: i,
    name: `Edward King ${i}`,
    age: 32,
    address: `London, Park Lane no. ${i}`,
  });
}

const ProductDetails = () => {
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [loading, setLoading] = useState(false);

  const start = () => {
    setLoading(true); // ajax request after empty completing

    setTimeout(() => {
      setSelectedRowKeys([]);
      setLoading(false);
    }, 1000);
  };

  const onSelectChange = (newSelectedRowKeys) => {
    console.log("selectedRowKeys changed: ", selectedRowKeys);
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };
  const hasSelected = selectedRowKeys.length > 0;
  return (
    <div>
      <div
        style={{
          marginBottom: 16,
        }}
      >
        <Button
          type="primary"
          onClick={start}
          disabled={!hasSelected}
          loading={loading}
        >
          Reload
        </Button>
        <span
          style={{
            marginLeft: 8,
          }}
        >
          {hasSelected ? `Selected ${selectedRowKeys.length} items` : ""}
        </span>
      </div>
      <div class="flex px-5 gap-6">
        <div class="w-1/2 box-border border-2 shadow-md rounded-md">
          <div className="grid grid-cols-2 gap-4">
            <div class=" flex justify-end pt-1">
              <div></div>
            </div>
          </div>

          <Table
            size="small"
            rowSelection={rowSelection}
            columns={columns}
            dataSource={data}
            pagination={{
              pageSize: 5,
              showSizeChanger: false,
            }}
          />
        </div>
        <div class="w-1/2 box-border border-2 shadow-md rounded-md">
          <div className="grid grid-cols-2 gap-4">
            <div className=" flex justify-end pt-1">
              <div></div>
            </div>
          </div>
          <div className="h-20">

          </div>
          <div className="h-60">

         <ProductCharts />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductDetails;
